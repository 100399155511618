import React, { useState }                                                                  from 'react';
import { graphql }                                                                          from 'gatsby';
import Img                                                                                  from 'gatsby-image';
import { GiGemChain, GiWatch, GiDiamondRing, GiAbstract111, GiRolledCloth, GiCandleHolder } from 'react-icons/gi';
import { PiFlowerTulipFill }                                                                from 'react-icons/pi';
import { RiInkBottleFill }                                                                  from 'react-icons/ri';
import { Box, useBreakpointValue }                                                          from '@chakra-ui/react';

import { findT } from '@interness/web-core/src/components/_helpers';
import Link      from '@interness/web-core/src/components/elements/Link/Link';
import routes    from '@interness/web-core/config/routes';
import Logo      from '@interness/web-core/src/components/media/Logo/Logo';
import Button    from '@interness/web-core/src/components/elements/Button/Button';
import SEO       from '@interness/web-core/src/components/modules/SEO/SEO';
import BrandsSubnavigation
                 from '@interness/brands-addon/src/components/modules/BrandsSubnavigation/BrandsSubnavigation';
import Spacer    from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper   from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading   from '@interness/web-core/src/components/text/Heading/Heading';
import RandomBrandHeaderImages
                 from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';

import * as S       from './styles';
import CallToAction from '../../../../components/CallToAction/CallToAction';

const GridItem = ({ brand, lang }) => {
  let touched = false;
  const [hovering, setHovering] = useState(false);
  if (brand.gallery_images_square.length === 0) {
    console.log(brand.display_name);
  }
  return (
    <Link to={`${routes[brand.type.type][lang]}/${brand.slug}`}
          onTouchStart={() => (touched = true)}
          onMouseEnter={() => {
            if (!touched) {
              setHovering(true)
            }
          }}
          onMouseLeave={() => {
            if (!touched) {
              setHovering(false)
            }
          }}
    >
      <S.GridItem hovering={hovering}>
        <S.BrandLogo visibility={hovering ? 'visible' : 'hidden'}>
          <Logo logo={brand.logo.localFile} title={brand.display_name} svgFill={'#000'} svgWidth="240px"
                svgHeight="80px"/>
          <Button>Mehr erfahren</Button>
        </S.BrandLogo>
        <Img className="background" fluid={brand.gallery_images_square[0].file.localFile.childImageSharp.fluid}
             alt={brand.display_name}/>
        <S.Overlay>
          <h4>{brand.display_name}</h4>
        </S.Overlay>
      </S.GridItem>
    </Link>
  )
};

const GridItemRolex = ({ brand, lang }) => {
  if (brand.gallery_images_square.length === 0) {
    console.log(brand.display_name);
  }
  return (
    <Link to={`${routes[brand.type.type][lang]}/${brand.slug}`}
    >
      <S.GridItem>
        <Img className="background" fluid={brand.gallery_images_square[0].file.localFile.childImageSharp.fluid}
             alt={brand.display_name}/>
      </S.GridItem>
    </Link>
  )
};

const BrandsPageTemplate = props => {
  const { type, lang } = props.pageContext;
  const brands = props.data.allDirectusBrands.nodes;
  const brandInfo = findT(props.data.directusBrandTypes.translations, lang);
  const showBanner = useBreakpointValue(
    {
      base: 'none',
      sm: 'none',
      md: 'block',
      lg: 'block',
      xl: 'block',
      '2xl': 'block',
    },
    {
      fallback: 'md',
    },
  )
  const headings = {
    watches: {
      title: 'Feine Uhren',
      icon: <GiWatch/>
    },
    jewelry: {
      title: 'Erlesener Schmuck',
      icon: <GiGemChain/>
    },
    wedding_rings: {
      title: 'Trauringe',
      icon: <GiDiamondRing/>
    },
    engagement_rings: {
      title: 'Verlobungsringe',
      icon: <GiDiamondRing/>
    },
    accessories: {
      title: 'Accessories',
      icon: <GiAbstract111/>
    },
    home_fabrics: {
      title: 'Heimtextilien',
      icon: <GiRolledCloth/>
    },
    decoratives: {
      title: 'Dekoratives',
      icon: <GiCandleHolder/>
    },
    room_fragrances: {
      title: 'Raumdüfte',
      icon: <RiInkBottleFill/>
    },
    floral_workshop: {
      title: 'Florale Werkstatt',
      icon: <PiFlowerTulipFill />
    }
  }
  return (
    <>
      <SEO title={brandInfo.display_name}/>
      <BrandsSubnavigation type={type}/>
      <Spacer height={20}/>
      <Wrapper>
        <Heading
          icon={headings[type].icon}
          subtitle={`${findT(props.data.directusProject.translations, lang).seo_title}`}>{headings[type].title}</Heading>
        <Spacer height={20}/>
      </Wrapper>
      <Box display={showBanner}>
        <RandomBrandHeaderImages type={type}/>
      </Box>
      <Spacer height={40}/>
      <Wrapper>
        <S.Grid>
          {brands.map(({ brand }) => {
            if (brand.slug === 'rolex') {
              return <GridItemRolex brand={brand} key={`${brand.type.type}/${brand.display_name}`} lang={lang}/>
            } else {
              return (
                <GridItem brand={brand} key={`${brand.type.type}/${brand.display_name}`} lang={lang}/>
              )
            }
          })}
        </S.Grid>
        <Spacer/>
        <CallToAction/>
      </Wrapper>
    </>
  )
};

export const query = graphql`
    query($type: String!) {
        directusBrandTypes(type: {eq: $type}) {
            type
            translations {
                language
                display_name
                subtitle
                description
                short_description
            }
        }
        allDirectusBrands(filter: {brand: {type: {type: {eq: $type}}, status: {eq: "published"}}, id: {ne: "dummy"}}, sort: {fields: brand___brand_rating, order: DESC}) {
            nodes {
                ...BrandsCore
                brand {
                    gallery_images_square {
                        file {
                            width
                            height
                            localFile {
                                name
                                childImageSharp {
                                    fluid(maxWidth: 250, maxHeight: 250, quality: 100) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        directusProject {
            translations {
                seo_title
                language
            }
        }
    }
`;

export default BrandsPageTemplate;
