import styled   from '@emotion/styled';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 15px;
  //IE Fallback
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    a {
      display: block;
      min-width: 250px;
      min-height: 250px;
      width: 300px;
      height: 300px;
      margin: 0 auto 15px auto;
    }
  }
`;

export const GridItem = styled.div`
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  transition: all 0.2s;
  border: 5px solid;
  border-color: ${props => props.theme.colorMode === 'dark' ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.7)'} !important;
  box-shadow: ${props => props.hovering ? '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)' : '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'};
  img {
    filter: grayscale(100%);
    opacity: ${props => props.hovering ? 1.0 : 0.8};
  }
`;

export const Overlay = styled.div`
  z-index: 1;
  text-align: center;
  h4 {
    padding: 10px 0;
    margin: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${props => props.theme.colorMode === 'dark' ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.7)'};
    color: ${props => props.theme.colorMode === 'light' ? '#fff' : '#333'} !important;
  }
  border-radius: 0 0 2px 0;
  p {
    margin-bottom: 0;
  }
`;

export const BrandLogo = styled.div`
  z-index: 2;
  position: absolute;
  transition: opacity 0.2s;
  opacity: ${({ visibility }) => visibility === 'hidden' ? 0 : 1};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,0.9);
`;

export const TypeDescription = styled.div`
  text-align: justify;
  h3 {
    text-align: center;
  }
`;